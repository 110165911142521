@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);

img[data-v-8752a08a] {
    height: var(--badge-height);
}


.faux-link-button[data-v-cbb2a184] {
    color: var(--color-primary);
    font: inherit;
    text-decoration: underline;
}
.v-btn[data-v-cbb2a184] {
    --pressed-button-color: var(--color-dark);
    letter-spacing: inherit;
    text-align: center;
    text-transform: inherit;
}
.v-btn.v-btn--outlined[data-v-cbb2a184]:not(.theme--dark) {
    background: white;
    border: 1px solid #D0D5D9;
}

/* Large buttons get special white space and wrapping? */
.v-btn.v-size--large[data-v-cbb2a184] {
    font-size: var(--type-base);
    height: auto;
    padding: 0.85em var(--spacing-8);
    white-space: normal;
}
.v-btn.v-size--large[data-v-cbb2a184] .v-btn__content {
    -ms-flex-negative: 1;
        flex-shrink: 1;
}
.v-btn[aria-pressed][data-v-cbb2a184] {
    background: var(--pressed-button-color) !important;
    border-color: transparent !important;
    color: var(--color-white) !important;
}
.theme--light.v-btn.v-btn--disabled[data-v-cbb2a184]:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #e0e0e0 !important;
}

/* Enable never showing as active.
https://github.com/vuetifyjs/vuetify/issues/8172#issuecomment-738304152 */
.v-btn--active.never-active[data-v-cbb2a184]:not(:focus):not(:hover)::before {
    opacity: 0 !important;
}


.content-container[data-v-b443ec5c] {
    margin-inline: auto;
    max-width: min(1344px, calc(1344 / 1440 * 100vw));
}
.section-heading[data-v-b443ec5c] {
    font-size: calc(var(--type-title) * 0.85);
}
.block-link[data-v-b443ec5c] {
    text-decoration: none;
}
.unstyled-list[data-v-b443ec5c] {
    list-style: none;
    padding: 0;
}
.sign-up-follower[data-v-b443ec5c] {
    margin: var(--spacing-4);
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    z-index: 1;
}
.sign-up-follower[data-offscreen][data-v-b443ec5c] {
    -webkit-transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
            transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
}
.landing-page-header[data-v-b443ec5c] {
    /* align-items: center; */
    background: var(--color-secondary);
    -webkit-box-shadow: 0 0 10px black;
            box-shadow: 0 0 10px black;
    color: white;
    /* display: flex; */
    /* justify-content: center; */
    padding: var(--spacing-4);
    position: relative;
    text-align: center;
    z-index: 1;
}
.iseechange-logo[data-v-b443ec5c] {
    vertical-align: -7px; /* Baseline from image. */
}
.logo-joiner[data-v-b443ec5c] {
    display: inline-block;
    font-size: 40px;
    margin-inline: 1ch;
}
[data-narrow] .logo-joiner[data-v-b443ec5c] {
    font-size: 30px;
}
.partner-logo[data-v-b443ec5c] {
    vertical-align: calc(var(--logo-baseline) * var(--logo-scale) * -1px);
}
.partner-name[data-v-b443ec5c] {
    border-radius: 5px;
    display: inline-block;
    font-family: var(--type-headline);
    font-size: 40px;
    font-weight: bold;
}
[data-narrow] .partner-name[data-v-b443ec5c] {
    font-size: 30px;
}
.hero[data-v-b443ec5c] {
    background: var(--color-secondary);
    color: white;
    padding: 5vh 0;
    position: relative;
}
.hero-cta-button[data-v-b443ec5c] {
    text-shadow: none;
}
.hero-background[data-v-b443ec5c] {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}
.hero-underlay[data-v-b443ec5c] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0006), to(#0000));
    background: linear-gradient(#0006 50%, #0000);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.hero-container[data-v-b443ec5c] {
    position: relative;
}
.hero-content[data-v-b443ec5c] {
    text-shadow: 0 2px 5px black;
}
.registration-form-wrapper[data-v-b443ec5c] {
    -webkit-filter: drop-shadow(0 10px 10px #0003);
            filter: drop-shadow(0 10px 10px #0003);
    margin-inline: auto;
    max-width: 60ch;
    scroll-margin-top: calc(var(--webnavigation--height) + var(--spacing-4));
}
.registration-form-wrapper[data-v-b443ec5c]:not([data-narrow]) {
    margin-bottom: calc(-5vh - 3em);
}
.registration-form-header[data-v-b443ec5c] {
    background: linear-gradient(135deg, #A2CD3D, #44ACAD);
    border-radius: 20px 20px 0 0;
    color: white;
    padding: var(--spacing-4) var(--spacing-8);
    text-align: center;
}
.registration-form-heading[data-v-b443ec5c] {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    letter-spacing: 0.05ch;
    margin: 0;
    text-transform: uppercase;
}
.registration-form-content[data-v-b443ec5c] {
    background: white;
    border-radius: 0 0 20px 20px;
    color: black;
    padding: var(--spacing-4) var(--spacing-8);
}
.girl-scouts[data-v-b443ec5c] {
    background: var(--color-light);
}
.girl-scouts-block[data-v-b443ec5c] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: center;
}
[data-smaller-icons] .girl-scouts-icon[data-v-b443ec5c] {
    height: auto;
    width: 80px;
}
.whitby-section[data-v-b443ec5c] {
    background: var(--color-light);
    font-size: calc(var(--type-subtitle) / (32 / 26));
}
.how-it-works[data-v-b443ec5c] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, #0000), to(#0001));
    background: linear-gradient(#0000 75%, #0001);
}
.how-it-works-icon-wrapper[data-v-b443ec5c] {
    position: relative;
}
.how-it-works-entry:not(:last-child) > .how-it-works-icon-wrapper[data-v-b443ec5c]:after {
    background: var(--color-primary);
    content: "";
    height: 120%; /* This isn't perfect, but it's probably flexible enough. */
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    top: 50%;
    width: 1.5rem;
}
.how-it-works-icon[data-v-b443ec5c] {
    /* margin-block: var(--spacing-8); */
    position: relative;
    z-index: 1;
}
.how-it-works-explanation[data-v-b443ec5c] {
    font-size: calc(var(--type-subtitle) / (24 / 16))1;
    -webkit-margin-end: 1em;
            margin-inline-end: 1em;
}
.how-it-works-explanation[data-v-b443ec5c] h3 {
    color: var(--color-danger);
    font-size: var(--type-subtitle);
}
.screenshots-layout[data-v-b443ec5c] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.screenshots-layout[data-v-b443ec5c] > :first-child {
    margin: 4rem -4rem 0 0;
    position: relative;
    z-index: 1;
}
.screenshot[data-v-b443ec5c] {
    -webkit-mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
            mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
    -webkit-mask-size: cover;
            mask-size: cover;
    min-width: 0;
}
.investigation-image[data-v-b443ec5c] {
    aspect-ratio: 20 / 13;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
}
.post-container[data-v-b443ec5c] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0008), to(#0000)), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    background: linear-gradient(#0008 50%, #0000), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    width: 100%;
}
.post-content[data-v-b443ec5c] {
    aspect-ratio: 1;
    color: white;
}
.media[data-v-b443ec5c] {
    background: #8882;
}
.sharing[data-v-b443ec5c] {
    background: linear-gradient(135deg, #A2CD3D55, #44ACAD55);
}
.icon-link[data-v-b443ec5c] {
    color: inherit;
    text-decoration: inherit;
}


select[data-v-06a6b09e] {
    color: inherit;
    width: 1.7em;
}


.footer-links-root[data-v-3a4c47fa] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: -1ch; /* Restore links' original positions. */
}
.footer-links-root ul[data-v-3a4c47fa] {
    display: inline;
    padding: 0;
    vertical-align: middle;
}
.footer-links-root li[data-v-3a4c47fa] {
    display: inline;
}
.footer-links-root a[data-v-3a4c47fa] {
    color: inherit;
    display: inline-block;
    padding: 1ch;
    text-decoration: none;
}
.footer-links-root .copyright[data-v-3a4c47fa] {
    margin-inline: 2em 1ch;
}


.campaign-landing-page-root[data-v-0d350094] {
    --wave: 5vw;
    background: var(--color-royal);
    color: var(--color-white);
    font: normal 16px/24px var(--type-sans);
}
.content[data-v-0d350094] {
    margin: 0 auto;
    max-width: 55em;
    padding: 0 1em;
}
.wavy-top[data-v-0d350094] {
    position: relative;
    z-index: 1;
}
.wavy-top[data-v-0d350094]::before {
    background: inherit;
    content: "";
    inset: calc(var(--wave) * -1 + 2px) 0 calc(100% - 2px) 0;
    -webkit-mask: url("/img/campaigns/wavy-top-mask.svg");
            mask: url("/img/campaigns/wavy-top-mask.svg");
    -webkit-mask-size: cover;
            mask-size: cover;
    position: absolute;
    z-index: -1;
}
header[data-v-0d350094] {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0008), to(#0008)), var(--main-image, none);
    background-image: linear-gradient(#0008, #0008), var(--main-image, none);
    background-size: cover;
    background-position: var(--main-image-position);
    /* margin-block-end: calc(var(--wave) * -1); */
    padding: var(--wave) var(--wave) calc(var(--wave) *2);
    text-align: center;
}
[data-narrow] header[data-v-0d350094] {
    text-align: start;
}
.logos[data-v-0d350094] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 16px;
    -webkit-margin-after: var(--wave);
            margin-block-end: var(--wave);
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
[data-narrow] .logos[data-v-0d350094] {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.logos[data-v-0d350094] :is(img, svg) {
    max-height: calc(54px * var(--scale, 1));
}
[data-narrow] .logos[data-v-0d350094] :is(img, svg) {
    max-height: calc(40px * var(--scale, 1));
}
main[data-v-0d350094] {
    background: var(--color-royal);
    font: normal 20px/24px var(--type-sans);
    padding-block: 1px;
}
h1[data-v-0d350094] {
    font: 500 normal 40px/1.25 var(--type-headline);
    text-wrap-style: balance;
}
[data-narrow] h1[data-v-0d350094] {
    font-size: calc(25rem / 16);
    line-height: 1.5;
}
.description-and-form[data-v-0d350094] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    z-index: 1;
}
[data-narrow] .description-and-form[data-v-0d350094] {
    display: block;
}
.description-area[data-v-0d350094] {
    line-height: 1.5;
    max-width: 45ch;
}
[data-narrow] .description-area[data-v-0d350094] {
    max-width: none;
}
.description-area > h2[data-v-0d350094] {
    font: normal 24px/32px var(--type-headline);
    margin: -0.2em 0 1em 0;
}
[data-narrow] .description-area > h2[data-v-0d350094] {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}
.description[data-v-0d350094] ul {
    padding: 0;
}
.description[data-v-0d350094] ul > li {
    margin: 2em 0 2em 2em;
    -webkit-padding-start: 1em;
            padding-inline-start: 1em;
    position: relative;
}
[data-narrow] .description[data-v-0d350094] ul > li {
    -webkit-margin-start: 0;
            margin-inline-start: 0;
}
@supports selector(*::marker) {
.description[data-v-0d350094] ul > li::marker {
        content: "check";
        color: var(--color-danger);
        font-family: 'Material Icons';
}
[data-narrow] .description[data-v-0d350094] ul > li::marker {
        color: transparent;
}
}
.description[data-v-0d350094] a {
    color: inherit;
}
.tracker-block[data-v-0d350094] {
    background: var(--color-white);
    border-radius: 15px;
    -webkit-box-shadow: 0 20px 40px -30px black;
            box-shadow: 0 20px 40px -30px black;
    color: var(--color-dark);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: min(40rem, 80vh);
    -webkit-margin-before: calc(var(--wave) * -1.2);
            margin-block-start: calc(var(--wave) * -1.2);
    overflow: hidden;
    padding: 4px;
    width: 35ch;
}
[data-narrow] .tracker-block[data-v-0d350094] {
    -webkit-margin-before: 32px;
            margin-block-start: 32px;
    width: auto;
}
.tracker[data-v-0d350094] {
    border: 0;
    border-radius: 12px;
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    width: 100%;
}
.quotes[data-v-0d350094] {
    background: #ECF6FF;
    color: var(--color-black);
    -webkit-margin-before: calc(var(--wave) * 2);
            margin-block-start: calc(var(--wave) * 2);
    -webkit-padding-before: 48px;
            padding-block-start: 48px;
}
.quote[data-v-0d350094] {
    margin: 0 auto;
    max-width: 24em;
}
.quote > figcaption[data-v-0d350094] {
    color: var(--color-blue-ocean);
}
hr[data-v-0d350094] {
    border: 1px solid #A8CBFF;
    border-width: 1px 0 0 0;
    margin-block: 72px 48px;
}
.in-the-media > h2[data-v-0d350094] {
    font: inherit;
    text-align: center;
}
.in-the-media > ul[data-v-0d350094] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin: -1em;
    padding: 0;
}
[data-narrow] .in-the-media > ul[data-v-0d350094] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.in-the-media > ul > li[data-v-0d350094] {
    -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    margin: 1em;
    text-align: center;
}
footer[data-v-0d350094] {
    padding: 24px 16px 16px; /* TODO: What's going on here? */
}


.async-image-wrapper[data-v-e4c5038e] {
    background-color: var(--color-light-tint);
    position: relative;
}
.async-image-wrapper[data-v-e4c5038e]::before {
    content: "";
    display: block;
    padding-top: calc(100% / var(--aspect-ratio));
}
.async-image[data-v-e4c5038e] {
    height: 100%;
    left: 0;
    -o-object-fit: contain;
       object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
}
.async-image[data-crop][data-v-e4c5038e] {
    -o-object-fit: cover;
       object-fit: cover;
}
.crop-icon[data-v-e4c5038e] {
    bottom: 0;
    left: 0;
    margin: 1rem;
    opacity: 0.5;
    position: absolute;
    text-shadow: 1.5px 1.5px black;
}


.how-it-works[data-v-34d2a202] {
  background-color: var(--color-light-tint);
  padding: 64px 0;
}
.how-it-works img[data-v-34d2a202] {
  max-height: 120px;
}


.hero[data-v-5173c9bc] {
  background:
    -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0006), to(#0000)),
    url("https://images.prismic.io/iseechange/c386403f-637c-4b7b-935e-60354355fff3_new-orleans-family.jpg?auto=compress,format") 50% 30%;
  background:
    linear-gradient(#0006 50%, #0000),
    url("https://images.prismic.io/iseechange/c386403f-637c-4b7b-935e-60354355fff3_new-orleans-family.jpg?auto=compress,format") 50% 30%;
  background-size: cover;
  color: white;
  min-height: 35vw;
  padding: 5rem 0;
}
.non-obvious-link[data-v-5173c9bc] {
  text-decoration: none;
}
.story-background[data-v-5173c9bc] {
  background:
    -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0006), to(#0000)),
    var(--story-image, #888) 50% 50%;
  background:
    linear-gradient(#0006 50%, #0000),
    var(--story-image, #888) 50% 50%;
  background-size: cover;
}
.story-block[data-v-5173c9bc] {
  color: white;
  display: block;
  min-height: 20vw;
  padding: 1rem;
}
.story-tags[data-v-5173c9bc] {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.story-tag[data-v-5173c9bc] {
  background: var(--color-danger, black);
  display: inline-block;
  margin-right: 1ch;
  padding: 0 1ch;
}


/*# sourceMappingURL=campaign.2f8c0ca1.css.map*/