<template>
    <div class="campaign-landing-page-root" :data-narrow="$vuetify.breakpoint.smAndDown">
        <header
            :style="{
                '--main-image': `url(/img/campaigns/${$route.params.campaign}/${t('mainImage.src')})`,
                '--main-image-position': String(t('mainImage.position')),
            }"
        >

            <div class="content">
                <div class="logos">
                    <primary-logo class="isc-logo" />

                    <img
                        v-for="i in Object.keys(t('partnerLogos') ?? {})"
                        :key="i"
                        :src="`/img/campaigns/${$route.params.campaign}/${t(`partnerLogos.${i}.file`)}`"
                        :alt="String(t(`partnerLogos.${i}.alt`))"
                        class="partner-logo"
                        :style="{ '--scale': String(t(`partnerLogos.${i}.scale`) ?? 1) }"
                    >
                </div>

                <h1 class="page-headline">{{ t('headline') }}</h1>
            </div>
        </header>

        <main class="wavy-top">
            <div class="content">
                <div class="description-and-form">
                    <article class="description-area">
                        <h2>{{ t('descriptionHeading') }}</h2>

                        <markdown-output class="description" :value="t('description')" />

                        <div class="text-center">
                            <auth-button x-large color="primary" min-width="30ch">{{ $t('campaignLandingPages.signUpCta') }}</auth-button>
                        </div>

                    </article>

                    <div class="tracker-block">
                        <iframe :src="trackerUrl.href" allow="geolocation; microphone; camera" class="tracker" />
                    </div>
                </div>
            </div>

            <div class="quotes wavy-top">
                <figure class="quote content">
                    <blockquote>
                        <p>{{ t('quote') }}</p>
                    </blockquote>

                    <figcaption>{{ t('quoteAttribution') }}</figcaption>
                </figure>

                <hr>

                <article class="in-the-media">
                    <h2>{{ t('asSeenInHeading') }}</h2>
                    <ul>
                        <li v-for="i in Object.keys(t('asSeenInLogos') ?? {})" :key="i">
                            <img
                                :src="`/img/campaigns/${$route.params.campaign}/${t(`asSeenInLogos.${i}.file`)}`"
                                :alt="String(t(`asSeenInLogos.${i}.alt`) ?? '')"
                                :width="String(t(`asSeenInLogos.${i}.width`) ?? '')"
                                :height="String(t(`asSeenInLogos.${i}.height`) ?? '')"
                            >
                        </li>
                    </ul>
                </article>
            </div>
        </main>

        <footer>
            <footer-links />
        </footer>
    </div>
</template>

<script lang="ts">
import AuthButton from '@/components/AuthButton.vue';
import FooterLinks from '@/components/FooterLinks.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';
import MarkdownOutput from '@/components/MarkdownOutput.vue';
import { TRACKER_ORIGIN } from '@/config';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    metaInfo(this: any, ) {
        return {
            title: this.pageTitle,
        };
    },

    components: {
        AuthButton,
        FooterLinks,
        MarkdownOutput,
        PrimaryLogo,
    },

    data(this: any) {
        return { pageTitle: this.t('title') };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        trackerUrl(): URL {
            const embedRootPath = this.$route.path.replace(this.$route.params.pathMatch, '*');
            const embeddedUrl = new URL(location.href);
            embeddedUrl.pathname = embedRootPath;

            const trackerPath = String(this.t('trackerPath') ?? '/flooding');
            const trackerUrl = new URL(trackerPath, TRACKER_ORIGIN);
            trackerUrl.searchParams.set('embedded-href', embeddedUrl.href);
            return trackerUrl;
        },
    },

    methods: {
        t(path: string) {
            const fullPath = `campaignLandingPages.${this.$route.params.campaign}.${path}`;
            if (this.$te(fullPath)) return this.$t(fullPath);
            if (this.$te(fullPath, 'en')) return this.$t(fullPath, 'en');
            return null;
        },
    },
});
</script>

<style scoped>
.campaign-landing-page-root {
    --wave: 5vw;
    background: var(--color-royal);
    color: var(--color-white);
    font: normal 16px/24px var(--type-sans);
}

.content {
    margin: 0 auto;
    max-width: 55em;
    padding: 0 1em;
}

.wavy-top {
    position: relative;
    z-index: 1;
}

.wavy-top::before {
    background: inherit;
    content: "";
    inset: calc(var(--wave) * -1 + 2px) 0 calc(100% - 2px) 0;
    mask: url("/img/campaigns/wavy-top-mask.svg");
    mask-size: cover;
    position: absolute;
    z-index: -1;
}

header {
    background-image: linear-gradient(#0008, #0008), var(--main-image, none);
    background-size: cover;
    background-position: var(--main-image-position);
    /* margin-block-end: calc(var(--wave) * -1); */
    padding: var(--wave) var(--wave) calc(var(--wave) *2);
    text-align: center;
}

[data-narrow] header {
    text-align: start;
}

.logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-block-end: var(--wave);
    justify-content: space-evenly;
}

[data-narrow] .logos {
    justify-content: space-between;
}

.logos :is(img, svg) {
    max-height: calc(54px * var(--scale, 1));
}

[data-narrow] .logos :is(img, svg) {
    max-height: calc(40px * var(--scale, 1));
}

main {
    background: var(--color-royal);
    font: normal 20px/24px var(--type-sans);
    padding-block: 1px;
}

h1 {
    font: 500 normal 40px/1.25 var(--type-headline);
    text-wrap-style: balance;
}

[data-narrow] h1 {
    font-size: calc(25rem / 16);
    line-height: 1.5;
}

.description-and-form {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}

[data-narrow] .description-and-form {
    display: block;
}

.description-area {
    line-height: 1.5;
    max-width: 45ch;
}

[data-narrow] .description-area {
    max-width: none;
}

.description-area > h2 {
    font: normal 24px/32px var(--type-headline);
    margin: -0.2em 0 1em 0;
}

[data-narrow] .description-area > h2 {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
}

.description :deep(ul) {
    padding: 0;
}

.description :deep(ul > li) {
    margin: 2em 0 2em 2em;
    padding-inline-start: 1em;
    position: relative;
}

[data-narrow] .description :deep(ul > li) {
    margin-inline-start: 0;
}

@supports selector(*::marker) {
    .description :deep(ul > li::marker) {
        content: "check";
        color: var(--color-danger);
        font-family: 'Material Icons';
    }

    [data-narrow] .description :deep(ul > li)::marker {
        color: transparent;
    }
}

.description :deep(a) {
    color: inherit;
}

.tracker-block {
    background: var(--color-white);
    border-radius: 15px;
    box-shadow: 0 20px 40px -30px black;
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    height: min(40rem, 80vh);
    margin-block-start: calc(var(--wave) * -1.2);
    overflow: hidden;
    padding: 4px;
    width: 35ch;
}

[data-narrow] .tracker-block {
    margin-block-start: 32px;
    width: auto;
}

.tracker {
    border: 0;
    border-radius: 12px;
    display: block;
    flex: 1;
    width: 100%;
}

.quotes {
    background: #ECF6FF;
    color: var(--color-black);
    margin-block-start: calc(var(--wave) * 2);
    padding-block-start: 48px;
}

.quote {
    margin: 0 auto;
    max-width: 24em;
}

.quote > figcaption {
    color: var(--color-blue-ocean);
}

hr {
    border: 1px solid #A8CBFF;
    border-width: 1px 0 0 0;
    margin-block: 72px 48px;
}

.in-the-media > h2 {
    font: inherit;
    text-align: center;
}

.in-the-media > ul {
    align-items: center;
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
    margin: -1em;
    padding: 0;
}

[data-narrow] .in-the-media > ul {
    flex-direction: column;
}

.in-the-media > ul > li {
    flex-basis: 30%;
    margin: 1em;
    text-align: center;
}

footer {
    padding: 24px 16px 16px; /* TODO: What's going on here? */
}
</style>
